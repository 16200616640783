<template>
  <v-app style="background-color: #FAFAFA!important;">
    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<script>
import('@/assets/css/w3.css');
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
  font-family: "Mintage";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/mintage.ttf");
}

@font-face {
  font-family: "RosaSans";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/RosaSans-Regular.ttf");
}

.btn-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 42.34px;
  text-align: left;
}

@media (min-width: 1280px) {
  .v-container {
    max-width: 1300px !important;
  }

  .btn-text {
    font-size: 24px;
  }
}
</style>