export const redirections = [
  {
    path: "/app/:link?",
    beforeEnter() {
      window.location.href = `https://dash.1app.online`;
    },
  },
  {
    path: "/blog/:link",
    beforeEnter(to, from, next) {
      window.location.href = `https://blog.1app.online/${to.params.link}`;
    },
  },
  {
    path: "/api/:link?",
    beforeEnter() {
      window.location.href = `https://docs.1app.online`;
    },
  },
  {
    path: "/password_recovery",
    beforeEnter(to, from, next) {
      // return console.log(`https://1app.online${to.fullPath}`)
      window.location.href = `https://1app.online${to.fullPath}`;
    },
  },
  {
    path: "/pay/:id",
    beforeEnter(to, from, next) {
      window.location.href = `https://pay.1app.online${to.path.replaceAll(
        "/pay/",
        "/"
      )}`;
    },
  },
  {
    path: "/dl",
    redirect: (to) => {
      return "download";
    },
  },
  {
    path: "/downloads",
    redirect: (to) => {
      return "download";
    },
  },
  {
    path: "/privacy-policy",
    redirect: (to) => {
      return "privacy";
    },
  },
  {
    path: "/start",
    beforeEnter(to, from, next) {
      window.location.href = `https://useboldd.com${to.path}`;
    },
  },
  {
    path: "/login",
    beforeEnter(to, from, next) {
      window.location.href = `https://dash.1app.online`;
    },
  },
  {
    path: "/register",
    beforeEnter(to, from, next) {
      window.location.href = `https://dash.1app.online${to.path}`;
    },
  },
  {
    path: "/r",
    beforeEnter(to, from, next) {
      window.location.href = `https://dash.1app.online/register`;
    },
  },
  {
    path: "/r/:refcode",
    beforeEnter(to, from, next) {
      window.location.href = `https://dash.1app.online/register?referby=${to.params.refcode}`;
    },
  },
  {
    path: "/verifemail",
    beforeEnter(to, from, next) {
      window.location.href = `https://1app.online${to.fullPath}`;
    },
  },
  {
    path: "/verifphone",
    beforeEnter(to, from, next) {
      window.location.href = `https://1app.online${to.fullPath}`;
    },
  },
  {
    path: "/invoice/:id",
    beforeEnter(to, from, next) {
      window.location.href = `https://1app.online${to.fullPath}`;
    },
  },
];
