import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
let ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    stat: "",
    country: {
      alpha3: "NG",
      name: "Nigeria",
      route: "ng",
      file_url:
        "//upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
    },
  },
  getters: {
    getStat: (state) => state.stat,
    getCountry: (state) => state.country,
  },
  mutations: {
    updateStat: (state, data) => (state.stat = data),
    updateCountry: (state, data) => (state.country = data),
  },
  actions: {
    fetchStat: async (context) => {
      try {
        await fetch("https://api.oneappgo.com/homestats", {
          method: "POST",
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status) return context.commit("updateStat", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    checkPhone: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("phoneno", data.phone);
        return await fetch("https://api.oneappgo.com/checkdphone", {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchUserAccount: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("username", data.username);
        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/qs/listmyaccount",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    redeemCard: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("claimcode", data.claimcode);
        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/qs/previewgfcard",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    viewTickets: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("eventid", data.eventid);
        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/qs/viewtickets",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    submitContact: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("subject", data.subject);
        formData.append("message", data.body);
        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/qs/contact",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    usdPayment: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("token", data.token);
        formData.append("amount", data.amount);
        formData.append("currency", data.currency);
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("desc", data.desc);
        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/qs/payme",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    verifyEmail: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("vercode", data.token);
        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/qs/validatevermail",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    subscribeEmail: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("useremail", data.email);
        return await fetch("https://useboldd.com/api/tts/join-newsletter", {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
