export const countries = [
  {
    alpha3: "NG",
    name: "Nigeria",
    route: "ng",
    file_url:
      "//upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
  },
  {
    alpha3: "KE",
    route: "ke",
    name: "Kenya",
    file_url: "//upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
  },

  {
    alpha3: "GH",
    name: "Ghana",
    route: "home",
    file_url: "//upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
  },
  {
    alpha3: "RW",
    name: "Rwanda",
    route: "home",
    file_url:
      "//upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg",
  },
  {
    alpha3: "UG",
    name: "Uganda",
    route: "home",
    file_url:
      "//upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
  },
  {
    alpha3: "ZA",
    name: "South Africa",
    route: "home",
    file_url:
      "//upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg",
  },
  {
    alpha3: "ZM",
    name: "Zambia",
    route: "home",
    file_url:
      "//upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg",
  },
  {
    alpha2: "UK",
    alpha3: "GB",
    route: "home",
    name: "United Kingdom of Great Britain and Northern Ireland",
    file_url:
      "//upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
  },
  {
    alpha2: "US",
    alpha3: "US",
    route: "home",
    name: "United States of America",
    file_url:
      "//upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
  },
];
